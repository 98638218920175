export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: '상황판',
        to: '/military',
        icon: 'cil-chart-line'
      },
      {
        _name: 'CSidebarNavItem',
        name: '상황 발생 이력 조회',
        to: '/srds_events',
        icon: 'cib-redis',
      },
      {
        _name: 'CSidebarNavItem',
        name: '데이터수집기 관리',
        to: '/srds_sites',
        icon: 'cil-sitemap',
      },
      {
        _name: 'CSidebarNavItem',
        name: '사용자 계정 관리',
        to: '/srds_users',
        icon: 'cil-user',
      },
      {
        _name: 'CSidebarNavItem',
        name: '로그인기록',
        to: '/loginhistory',
        icon: 'cil-lock-locked',
      }
    ]
  }
]