<template>
  <div class="c-app">
    <TheSidebar/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body" :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"  v-if="check_service_codeg">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
      <div class="c-body" v-else>
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import loopback from "@/services/loopback";
import utils from '@/services/utils';
import bgDay from "@/assets/background/bg_day.jpg";
import bgNight from "@/assets/background/bg_night.jpg";

export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter
  },
  data() {
    var SERVICE_TYPE = utils.getServiceTypes();

    return {
      dayImage: bgDay,
      nightImage: bgNight,
      sunriseTime: '06:00',
      sunsetTime: '18:00',
      is_admin: this.$store.state.auth.is_admin,
      service_type_info: _.map(SERVICE_TYPE.USER, function(value, key) {
        return { key: key, value: value}}
      )
      
    };
  },
  created: function() {
    this.user_info = this.$store.state.auth.user_info;
    this.user = this.user_info.user;
  },
  computed: {
    backgroundImage() {
      const now = new Date();
      const sunrise = new Date();
      sunrise.setHours(parseInt(this.sunriseTime.slice(0, 2)), parseInt(this.sunriseTime.slice(3)));
      const sunset = new Date();
      sunset.setHours(parseInt(this.sunsetTime.slice(0, 2)), parseInt(this.sunsetTime.slice(3)));
      
      if (now > sunrise && now < sunset) {
        return this.dayImage;
      } else {
        return this.nightImage;
      }
    },
    check_service_codeg () {
      return this.user.service_type == 'codeg';
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
